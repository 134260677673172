import './App.css';
import Navbar from './components/js/Navbar';
import ConditionalNavbar from './components/js/ConditionalNavbar';
import Footer from './components/js/Footer';
import { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import VideosPage from './pages/VideosPage';
import SourcesCreditsPage from './pages/SourcesCreditsPage';
import PredictPage from './pages/PredictPage';
import HomePage from './pages/HomePage';
import ExplanationPage from './pages/ExplanationPage';

function App() {
  

  return (
    <div className="App">
            {(
        <>
        {/* List of routes */}
      <Router>
        <ConditionalNavbar />
        <Routes>

          <Route path="/" element={<Navigate replace to="/home" />} />
          <Route path="/home" element={<HomePage/>} />

          <Route path="/predict" element={<PredictPage />} />
          <Route path="/videos" element={<VideosPage />} />
          <Route path="/sources-credits" element={<SourcesCreditsPage />} />
          <Route path="/explanation" element={<ExplanationPage />} />

        </Routes>
        <Footer />
      </Router>
      </>
            )}
    </div>
  );

}

export default App;
