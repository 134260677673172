import React from 'react';
import { NavLink } from 'react-router-dom';
import '../css/Navbar.css';

// Navbar component

const Navbar = () => {
  return (
    <nav className="navbar">
      {/* Logo */}
       <NavLink to="/home" activeclassname="active"><img src={require("../../images/jgu.png")} alt="Logo" className="navbar-logo" /></NavLink>
      <div className="navbar-links">
        {/* Link to Home page */}
        <NavLink to="/home" activeclassname="active">Home</NavLink>

        {/* Link to Predict page */}
        <NavLink to="/predict" activeclassname="active">Predict</NavLink>

        {/* Link to Videos page */}
        <NavLink to="/videos" activeclassname="active">Videos</NavLink>

        {/* Link to Explanation page */}
        <NavLink to="/explanation" activeclassname="active">Explanation</NavLink>
        
        {/* Link to Sources & Credits page */}
        <NavLink to="/sources-credits" activeclassname="active">Sources & Credits</NavLink>
      </div>
    </nav>
  );
};

export default Navbar;