import React, { useState } from 'react';


// Dropdown component definition

const Dropdown = ({ onDataUpdate, SpinnerLoading }) => {
  // State for selected option
  const [selectedOption, setSelectedOption] = useState("");
  // State to track loading status
  const [loading, setLoading] = useState(false);

  // Handles change in dropdown selection
  const handleChange = (event) => {

    // Handles special case for option "1" or gets value from event
    const value = event === "1" ? "1" : event.target.value;

    // Converts string to number for selected option
    const trueEvent = stringToNumber(value);

    // Updates selected option state
    setSelectedOption(trueEvent);

    // Fetches video based on class selection
    getVideoFromClass(trueEvent);
  };

  // Converts string to number for dropdown options
  function stringToNumber(string){
    switch(string) {
      case "1": return 1;
      case "2": return 2;
      case "3": return 3;
      default: return 0;
    }
  }

  // Fetches video data based on class and updates component state
  async function getVideoFromClass(classE){
    setLoading(true); // Sets loading state to true
    SpinnerLoading(true, "New video"); // Calls SpinnerLoading with loading status

    try {
      // Fetch request to server with classE as body
      const response = await fetch(`${process.env.REACT_APP_API_URL_BACKEND}/getVideoFromClass`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Allow-Control-Allow-Origin': '*', // Allows cross-origin requests
          'Allow-Access-Control-Allow-Origin': '*', 
        },
        body: JSON.stringify({ classE })
      });
      const data = await response.json(); // Parses JSON response
      await onDataUpdate(data); // Updates parent component with new data
    } catch (error) {
      console.error('Error fetching video data:', error); // Logs error to console
    } finally {
      // Resets loading state after 4 seconds
      setTimeout(() => {
        SpinnerLoading(false, "New video");
      }, 4000);
    }
  }

  // Renders dropdown component
  return (
    <div className="dropdown-container">
      <label className="dropdown-label" htmlFor="video-select">Select a video: </label>
      <select
        id="video-select"
        className="dropdown-select"
        value={selectedOption}
        onChange={handleChange}
      >
        {/* Dropdown options */}
        <option value="">Select an option</option>
        <option value="1">Reduced</option>
        <option value="2">Normal</option>
        <option value="3">Abnormal</option>
      </select>
      {loading} {/* Displays loading state */}
    </div>
  );
};

export default Dropdown;