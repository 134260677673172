import './css/ExplanationPage.css';
import React, { useState } from 'react';

/* The page listing each definition */

const ExplanationPage = () => {
  // The words and their definitions
  const wordDefinitions = {
    //"BRISQUE Score" : "The BRISQUE score assesses the quality of uploaded videos by analyzing natural scene statistics. It evaluates an image's perceived quality by comparing its statistical properties with those of a reference image set. A lower BRISQUE score signifies higher image quality, whereas a higher score indicates lower quality.",

    "Counterfactual" : "In cardiology, a counterfactual definition refers to a hypothetical scenario that is contrary to the actual facts or events. It is a thought experiment that imagines a different outcome or course of events, often used to evaluate the potential consequences of a particular action or decision.",
    
    "Diastolic" : "Diastolic refers to the phase of the cardiac cycle when the heart muscle relaxes and allows the chambers to fill with blood. It is the lowest pressure in the arteries when the heart is at rest between beats.",
    
    "Echocardiography" : "Echocardiography is a diagnostic test that uses ultrasound waves to create an image of the heart. It is a non-invasive procedure that provides detailed information about the structure and function of the heart, including the size, shape, and movement of the heart chambers and valves.",

    "LVEF" : "Left ventricular ejection fraction (LVEF) is the central measure of left ventricular systolic function. LVEF is the fraction of chamber volume ejected in systole (stroke volume) in relation to the volume of the blood in the ventricle at the end of diastole (end-diastolic volume).",

    "Segmentation" : "In cardiology, segmentation refers to the process of dividing an image into multiple segments or regions based on certain criteria or features. It is commonly used in medical imaging to identify and analyze specific structures or areas of interest within an image, such as the heart.",
    
    "Superpixel" : "A superpixel is a group of pixels that share similar characteristics or properties, such as color, texture, or intensity. Superpixels are used in image processing and computer vision to reduce the complexity of an image while preserving important features and structures. They are often used as building blocks for higher-level image analysis tasks, such as object detection and segmentation.",

    "Systolic" : "Systolic refers to the phase of the cardiac cycle when the heart muscle contracts and pumps blood from the chambers into the arteries. It is the highest pressure in the arteries when the heart beats and pushes blood out into the circulatory system.",
  };

  // useState for the search query
  const [searchQuery, setSearchQuery] = useState('');

  // Filtered list based on search query
  const filteredWords = Object.entries(wordDefinitions).filter(([word]) =>
    word.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="container">
      <h2>Explanation of the different technical terms used</h2>
      <hr className="hr-title"></hr>
      {/* Search bar */}
      <input
        type="text"
        placeholder="Search for a word..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="definition-search-bar"
      />
      <ul className="definition-list">
        {/* Iterate over each filtered word-definition pair and render them */}
        {filteredWords.map(([word, definition]) => (
          <li key={word}>
            <strong className="definition-name">{word}:</strong> <p className="definition-text">{definition}</p>
          </li>
        ))}
      </ul>
      <br />
    </div>
  );
};

export default ExplanationPage;