import React, { useState } from 'react';
import './css/VideosPage.css';

/* This page is used to display examples of each class of videos */

const VideosPage = () => {
  // Change to track only the currently zoomed image
  const [zoomedImg, setZoomedImg] = useState(null);

  // Update to handle zooming one image at a time
  const toggleZoom = (imgKey) => {
    if (zoomedImg === imgKey) {
      // If the same image is clicked again, de-zoom it
      setZoomedImg(null);
    } else {
      // Zoom the clicked image and de-zoom others
      setZoomedImg(imgKey);
    }
  };

  return (
    <div className="videos-page">
      <div className="text-section">
        {/* Explanation of the images */}
        <p>
          <strong>Welcome to the explanation page for the results of cardiac video analysis.</strong>
          <br /><br />
          Here, you can understand the results obtained and compare them with other videos. This will allow you to better interpret the results.
          <br /><br />
          On the first row: a normal heart. It appears to be in good condition, with the sides expanding well. 
          <br /><br />
          On the second one: a reduced heart, the valves do not expand sufficiently.
          <br /><br />
          On the final row: an abnormal heart, the valves are expanding too much. You can see it stretch a lot more than the other two types.
        </p>
      </div>

      {/* Images of the different types of hearts */}
      <div className="images-section">
        <div className="image-row">
          {/* Normal heart End systolic picture */}
          <div className="image">
            <img src={require("../images/examples/normal_min.png")} alt="Description 1" className={zoomedImg === 'img1' ? 'zoomed' : ''} onClick={() => toggleZoom('img1')} />
            <p className="image-text">End systolic</p>
          </div>
          {/* Reduced heart End systolic picture */}
          <div className="image">
            <img src={require("../images/examples/reduced_min.png")} alt="Description 4" className={zoomedImg === 'img4' ? 'zoomed' : ''} onClick={() => toggleZoom('img4')} />
            <p className="image-text">End systolic</p>
          </div>
          {/* Abnormal heart End systolic picture */}
          <div className="image">
            <img src={require("../images/examples/abnormal_min.png")} alt="Description 7" className={zoomedImg === 'img7' ? 'zoomed' : ''} onClick={() => toggleZoom('img7')} />
            <p className="image-text">End systolic</p>
          </div>
        </div>
        <div className="image-row">
          {/* Normal heart GIF */}
          <div className="image">
            <img src={require("../images/examples/normal.gif")} alt="Description 2" className={zoomedImg === 'img2' ? 'zoomed' : ''} onClick={() => toggleZoom('img2')} />
            <p className="image-text">Normal Heart</p>
          </div>
          {/* Reduced heart GIF */}
          <div className="image">
            <img src={require("../images/examples/reduced.gif")} alt="Description 5" className={zoomedImg === 'img5' ? 'zoomed' : ''} onClick={() => toggleZoom('img5')} />
            <p className="image-text">Reduced Heart</p>
          </div>
          {/* Abnormal heart GIF */}
          <div className="image">
            <img src={require("../images/examples/abnormal.gif")} alt="Description 8" className={zoomedImg === 'img8' ? 'zoomed' : ''} onClick={() => toggleZoom('img8')} />
            <p className="image-text">Abnormal Heart</p>
          </div>
        </div>
        <div className="image-row">
          {/* Reduced heart End diastolic picture */}
          <div className="image">
            <img src={require("../images/examples/reduced_max.png")} alt="Description 3" className={zoomedImg === 'img3' ? 'zoomed' : ''} onClick={() => toggleZoom('img3')} />
            <p className="image-text">End diastolic</p>
          </div>
          {/* Normal heart End diastolic picture */}
          <div className="image">
            <img src={require("../images/examples/normal_max.png")} alt="Description 6" className={zoomedImg === 'img6' ? 'zoomed' : ''} onClick={() => toggleZoom('img6')} />
            <p className="image-text">End diastolic</p>
          </div>
          {/* Abnormal heart End diastolic picture */}
          <div className="image">
            <img src={require("../images/examples/abnormal_max.png")} alt="Description 9" className={zoomedImg === 'img9' ? 'zoomed' : ''} onClick={() => toggleZoom('img9')} />
            <p className="image-text">End diastolic</p>
          </div>
        </div>
        <br />
      </div>
    </div>
  );
};

export default VideosPage;