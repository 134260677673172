import '../App.css';
import './css/PredictPage.css';
import Dropdown from '../components/js/Dropdown';
import { useState, useEffect } from 'react';
import axios from 'axios';
import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';


function PredictPage() {
  const generateGifPath = (filename) => {
    return `${process.env.REACT_APP_API_URL_FRONT_NODE}/generated_gifs/${filename}?${new Date().getTime()}`;
  };

  const [loading, setLoading] = useState(true); // Loading state
  const [pathVideo1, setPathVideo1] = useState(generateGifPath("../generated_gifs/vi1.gif")); // Path to the selected gif
  const [pathVideo2, setPathVideo2] = useState(generateGifPath("../generated_gifs/vi2.gif")); // Path to the counterfactual gif
  const [videoDataCF, setVideoDataCF] = useState(null); // Counterfactual video data
  const [videoDataBase, setVideoDataBase] = useState(null); // Selected video data
  const [zoomedImg, setZoomedImg] = useState(null); // Zoomed image state
  const [segmentedBase, setSegmentedBase] = useState(false); // Segmentation state for the selected video
  const [segmentedCF, setSegmentedCF] = useState(false); // Segmentation state for the counterfactual video
  const [brisqueBase, setBrisqueBase] = useState(null); // BRISQUE score for the selected video
  const [hasInitialDataLoaded, setHasInitialDataLoaded] = useState(false); // Initial data loaded state

  const [loading_type, setLoadingType] = useState("Application"); // Loading type to render in the loading state

  const [bes, setBes] = useState(1);  // Base end systolic
  const [bed, setBed] = useState(2);  // Base end diastolic
  const [ces, setCes] = useState(null); // Counterfactual end systolic
  const [ced, setCed] = useState(null); // Counterfactual end diastolic

  const [inQueue, setInQueue] = useState(false);  // Queue state
  const [queuePosition, setQueuePosition] = useState(null); // Queue position

  // Set the percentage of the Base video using Promise to wait for the value to be set
  function setVideoDataBasePromise(value) {
    return new Promise((resolve) => {
      setVideoDataBase(parseFloat(value).toFixed(2));
      resolve(value);
    });
  }

  // Set the percentage of the Counterfactual video
  function setVideoDataCFPromise(value) {
    return new Promise((resolve) => {
      setVideoDataCF(parseFloat(value).toFixed(2));
      resolve(value);
    });
  }

  // Set the Bes (Base end systolic) value and return a promise
  function setBesPromise(value) {
    return new Promise((resolve) => {
      setBes(parseFloat(value).toFixed(2));
      resolve(value);
    });
  }
  
  // Set the Bed (Base end diastolic) value and return a promise
  function setBedPromise(value) {
    return new Promise((resolve) => {
      setBed(parseFloat(value).toFixed(2));
      resolve(value);
    });
  }

  // Set the Ces (Counterfactual end systolic) value and return a promise
  function setCesPromise(value) {
    return new Promise((resolve) => {
      setCes(parseFloat(value).toFixed(2));
      resolve(value);
    });
  }

  // Set the Ced (Counterfactual end diastolic) value and return a promise
  function setCedPromise(value) {
    return new Promise((resolve) => {
      setCed(parseFloat(value).toFixed(2));
      resolve(value);
    });
  }

  // Fetch the data and set the loading state to false after
  useEffect(() => {
    async function fetchData() {
      setLoading(false);
    }
    fetchData();
  }, []);

  // Set the path of the selected gif depending on the segmentation state
  useEffect(() => {
    setPathVideo1(generateGifPath(segmentedBase ? "v1s.gif" : "vi1.gif"));
  }, [segmentedBase]);
  
  useEffect(() => {
    setPathVideo2(generateGifPath(segmentedCF ? "v2s.gif" : "vi2.gif"));
  }, [segmentedCF]);
  
  

  // Get the video data from the server depending on the class selected
  async function getVideoFromClass(classE){
    // Set the loading state to true and display a loading message
    setLoading(true);
    SpinnLoad(true, "New video");

    // Fetch the video data from the server
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL_BACKEND}/getVideoFromClass`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Allow-Control-Allow-Origin': '*',
          'Allow-Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({ classE })
      });
      const data = await response.json();
      await handleDataUpdate(data); // wait for the data to be handled
    } catch (error) { // In case of an error, log it
      console.error('Error fetching video data:', error);
    } finally {
      setTimeout(() => {
        SpinnLoad(false, "New video"); // Stop the loading state
      }, 4000);
    }
  }

  // Get the Base and Counterfactual video frames
  function frameTabLoading() {
    const gifPath_vi1 = "../videos/vi1/";
    const gifPath_vi2 = "../videos/vi2/";

    const gifFrames_vi1 = fetchGifFrames(gifPath_vi1, "vi1");
    const gifFrames_vi2 = fetchGifFrames(gifPath_vi2, "vi2");
  }

  // Get the frames of the segmented video depending on the video to load (selected or counterfactual)
  function segmentedFrameTabLoading(typeOfFrames) {
    if (typeOfFrames === "selected") {
      const gifPath_v1s = "../videos/vi1/sup";
      const gifFrames_v1s = fetchGifFrames(gifPath_v1s, "v1s");
    } else {
      const gifPath_v2s = "../videos/vi2/sup";
      const gifFrames_v2s = fetchGifFrames(gifPath_v2s, "v2s");
    }
  }

  // Function to get the frame from the specified name (vi1, vi2, v1s, v2s) with the frame number (ces, ced, bes, bed)
  function returnCurrentSideFrame(frame_tab_name, frame_type) {
    // In case of the selected video
    if (frame_tab_name === 'vi1') {
      if (frame_type === 'bed') {
        // return frame for the base end diastolic of vi1
        return frames_vi1[parseInt(bed)];
      } else {
        // return frame for the base end systolic of vi1
        return frames_vi1[parseInt(bes)];
      }
    }
    // In case of the counterfactual video
    else {
      if (frame_type === 'ced') {
        // return frame for the counterfactual end diastolic of vi2
        return frames_vi2[parseInt(ced)];
      } else {
        // return frame for the counterfactual end systolic of vi2
        return frames_vi2[parseInt(ces)];
      }
    }
  }

  // Table to store the frames of each video (vi1, vi2, v1s, v2s)
  const [frames_vi1, setFrames_vi1] = useState([]);
  const [frames_vi2, setFrames_vi2] = useState([]);
  const [frames_v1s, setFrames_v1s] = useState([]);
  const [frames_v2s, setFrames_v2s] = useState([]);
  
  // Function to return the current frame table depending on the modal opened (selected or counterfactual)
  function returnCurrentModalFrameName() {
    if (modalSource === 'selected') {
      if (segmentedBase) {
        return frames_v1s
      } else {
        return frames_vi1
      }
    } else {
      if (segmentedCF) {
        return frames_v2s
      } else {
        return frames_vi2
      }
    }
  }

  // Funtion to zoom the images when clicked
  const toggleZoom = (imgKey) => {
    if (zoomedImg === imgKey) {
      setZoomedImg(null);
    } else {
      setZoomedImg(imgKey);
    }
  };

  // Handler to update the data received from the server
  const handleDataUpdate = (data) => {
    setHasInitialDataLoaded(true);  // Set the initial data loaded state to true

    // Return a promise
    return new Promise(async (resolve, reject) => {
      const keysLength = Object.keys(data).length;  // Get the length of the data received
      if (keysLength > 0) {   // If the data is not empty
        handleUnsegment('both');  // Unsegment the videos
        try {
          const parsedData = JSON.parse(data[0]);   // Parse the data
          // Wait for the promises to be resolved
          await Promise.all([ 
            setVideoDataBasePromise(parseFloat(parsedData.base.bev[0]).toFixed(2)), // Set the percentage of the selected video
            setVideoDataCFPromise(parseFloat(parsedData.cf.bev[0]).toFixed(2)), // Set the percentage of the counterfactual video
            setBesPromise(parseFloat(parsedData.base.es[0]).toFixed(2)),  // Set the Bes value
            setBedPromise(parseFloat(parsedData.base.ed[0]).toFixed(2)),  // Set the Bed value
            setCesPromise(parseFloat(parsedData.cf.es[0]).toFixed(2)),  // Set the Ces value
            setCedPromise(parseFloat(parsedData.cf.ed[0]).toFixed(2))   // Set the Ced value
          ]);
          setBrisqueBase(null);
          resolve(parsedData);  // Resolve the promise
          frameTabLoading();    // Load the frames of the videos
          SpinnLoad(false, "Application");    // Stop the loading state
        } catch (error) {
          reject(error);
        }
      } else {
        reject(new Error("No data received"));
      }
    });
  };

  // Function to set the loading state and the loading type
  const SpinnLoad = (currentState, loadType) => {   
    setLoadingType(loadType);
    setLoading(currentState);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);  // Modal state
  const [modalSource, setModalSource] = useState(null);   // Modal source
  const [segmentationMethod, setSegmentationMethod] = useState('Slic');  // Segmentation method
  const [numberSuperpixels, setNumberSuperpixels] = useState(100);  // Number of superpixels
  const [compactness, setCompactness] = useState(10); // Compactness

  // Function to open the Segmentation modal
  const handleOpenModal = (source) => {
    setIsModalOpen(true);   // Set the modal state to true
    setModalSource(source);   // Set the modal source (selected or counterfactual)
  };

  // Function to close the Segmentation modal
  const handleCloseModal = () => {
    setIsModalOpen(false);  // Set the modal state to false
    setModalSource(null);   // Set the modal source to null
  };

  // Function to switch the displayed gif depending on the segmentation state and the source
  const handleSwitchSegmentationGif = (segmented) => {
    if (segmented) {
      if (modalSource === 'selected') {
        setPathVideo1(generateGifPath("../generated_gifs/v1s.gif"));
      } else {
        setPathVideo2(generateGifPath("../generated_gifs/v2s.gif"));
      }
    } else {
      if (modalSource === 'selected') {
        setPathVideo1(generateGifPath("../generated_gifs/vi1.gif"));
      } else {
        setPathVideo2(generateGifPath("../generated_gifs/vi2.gif"));
      }
    }
  };

  // Function to unsegment the videos
  const handleUnsegment = (source) => {
    // Set the path of the GIF to display the original video depending on the source
    if (source === 'selected') {
      setPathVideo1(generateGifPath("../generated_gifs/vi1.gif"));
      setSegmentedBase(false);  // Set the segmentation state to false
    } else if(source === 'counterfactual'){
      setPathVideo2(generateGifPath("../generated_gifs/vi2.gif"));
      setSegmentedCF(false);  // Set the segmentation state to false
    } else {
      setPathVideo1(generateGifPath("../generated_gifs/vi1.gif"));
      setPathVideo2(generateGifPath("../generated_gifs/vi2.gif"));
      setSegmentedBase(false);
      setSegmentedCF(false);
    }
  };

  // Function to handle the segmentation
  const handleSegment = async () => {
    SpinnLoad(true, "Segmentation");  // Set the loading state to true while segmenting

    let tmp_source = 0;
    if(modalSource === 'selected'){   // Set the source depending on the modal source
      tmp_source = 1;
    }
    else{
      tmp_source = 2;
    }

    handleUnsegment(modalSource); // Before segmenting, unsegment the video just in case

  // Payload to send to the server
  const payload = {
    source: tmp_source,
    method: segmentationMethod,
    superpixels: numberSuperpixels,
    compactness: compactness
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL_BACKEND}/segment`,
      payload, // Le payload est directement passé dans axios.post
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    const data = response.data; // Accès aux données de la réponse avec axios
  } catch (error) {
    console.error('Error during segmentation', error);
  }
    //console.log('Segmenting gif');
    //console.log(modalSource);

    if(modalSource === 'selected'){
      setSegmentedBase(true); // Set the segmentation state to true
      //console.log("Segmented Base");
      setPathVideo1(generateGifPath("../generated_gifs/v1s.gif"));  // Set the path of the GIF to the segmented video
      segmentedFrameTabLoading('selected'); // Load the frames of the segmented video
    }
    if(modalSource === 'counterfactual'){
      setSegmentedCF(true);   // Set the segmentation state to true
      //console.log("Segmented CF");
      setPathVideo2(generateGifPath("../generated_gifs/v2s.gif"));    // Set the path of the GIF to the segmented video
      segmentedFrameTabLoading('counterfactual'); // Load the frames of the segmented video
    }

    handleCloseModal();  // Close the segmentation modal
    handleSwitchSegmentationGif(true);  // Switch the displayed gif to the segmented video
    SpinnLoad(false, "Application");  // Stop the loading state
  };

  const [isModalGIFOpen, setIsModalGIFOpen] = useState(false);  // Modal GIF state
  const [sliderValue, setSliderValue] = useState(0);  // Slider value

  // Function to open the Frame by Frame modal for the GIF
  const handleOpenModalGIF = async (source) => {
    setIsModalGIFOpen(true);    // Set the modal GIF state to true
    setModalSource(source);     // Set the modal source (selected or counterfactual)
  };

  // Function to close the Frame by Frame modal
  const handleCloseModalGIF = () => {
    setIsModalGIFOpen(false); // Set the modal GIF state to false
    setModalSource(null);     // Set the modal source to null
  };

  // Function to handle the change of the slider value
  const handleSliderChange = (e) => {
    setSliderValue(e.target.value);
  };

  // Function to set the slider value to the next frame
  const nextFrame = () => {
    if (sliderValue < returnCurrentModalFrameName().length - 1) { // If the slider value is less than the number of frames
      setSliderValue(parseInt(sliderValue) + 1);  // Set the slider value to the next frame
    }
  };

  // Function to set the slider value to the previous frame
  const previousFrame = () => {
    if (sliderValue > 0) {  // If the slider value is greater than 0
      setSliderValue(parseInt(sliderValue) - 1);  // Set the slider value to the previous frame
    }
  };

  // Function to jump to the End Systolic frame depending on the source (selected or counterfactual)
  const esFrame = () => { 
    if(modalSource === 'selected'){
      setSliderValue(parseInt(bes));  // Set the slider value to the End Systolic frame of the selected video
    }
    else{
      setSliderValue(parseInt(ces));  // Set the slider value to the End Systolic frame of the counterfactual video
    }
  };

  // Function to jump to the End Diastolic frame depending on the source (selected or counterfactual)
  const edFrame = () => {
    if(modalSource === 'selected'){
      setSliderValue(parseInt(bed));  // Set the slider value to the End Diastolic frame of the selected video
    }
    else{
      setSliderValue(parseInt(ced));  // Set the slider value to the End Diastolic frame of the counterfactual video
    }
  };

  const [selectedFile, setSelectedFile] = useState(null); // Selected file state

  // Function to handle the change of the file input
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);   
  };

  // Function to handle the submit of the input form
  const handleSubmit = async (e) => {
    e.preventDefault();   // Prevent the default behavior of the form
    setLoading(true);   // Set the loading state to true
    setLoadingType("Uploading your video");
    const formData = new FormData();    // Create a new FormData object
    formData.append('video', selectedFile); // Append the selected file to the FormData object

    //console.log('Selected File type:', typeof selectedFile, '; content:', selectedFile);
    //console.log('Selected file:', selectedFile);

    await gettingVideoAnalysis(selectedFile); // Wait for the video analysis of the video to be done

    if(selectedFile === null){
      alert("No file selected")
      return;
    }
    else {
      console.log("File successfully uploaded")
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL_FRONT_NODE}//upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('Video uploaded successfully', response.data);
    } catch (error) {
      console.error('Error uploading video', error);
    }

    e.target.reset();   // Reset the form
    let modifiedPath = "../uploads/" + selectedFile.name;
    //console.log(modifiedPath);  
    let isUpload = true;
    await Processback(selectedFile.name, isUpload);  // Convert the uploaded video to a GIF


    setPathVideo1(generateGifPath("../generated_gifs/vi1.gif"));  // Set the path of the selected video
    setHasInitialDataLoaded(true);  // Set the initial data loaded state to true

    frameTabLoading();  // Load the frames of the videos
    setLoading(false);  // Set the loading state to false
    setLoadingType("Application");  // Set the loading type to Application
  };

  // Function to process the video back
  const Processback = async (path, isUpload) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL_BACKEND}/processVideo`, {
        filePath: path,
        isUpload: isUpload
      });
      setBrisqueBase(parseFloat(response.data).toFixed(2));   // Set the BRISQUE score of the selected video
      console.log('BRISQUE score:', response.data);

    } catch (error) {
      console.error('Error converting file', error);
    }
  };

  // Function to get the video analysis of the uploaded video
  const gettingVideoAnalysis = async (selectedFile) => {

    const formData = new FormData();  // Create a new FormData object
    formData.append('video', selectedFile);   // Append the selected file to the FormData object
    setLoadingType("Analyzing your video");   // Set the loading type to Analyzing your video

    console.log("Sending video to Analysis");

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL_BACKEND_COMPUTATION}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('Video uploaded successfully', response.data);

      const { analysis : [Bed, Bes, Lvef]} = response.data; // Get the analysis data from the response
      console.log("Analysis done, here are the data", response.data);

      setBedPromise(Bed);   // Set the Bed value
      setBesPromise(Bes);   // Set the Bes value
      setVideoDataBasePromise(Lvef * 100);    // Set the percentage of the selected video
      //copyImage(Bes, Bed)
      await getCFForUploadedVideo(Lvef * 100);    // Get the Counterfactual video for the uploaded video

    } catch (error) {
      console.error('Error uploading video', error);
    }

  };
  // Function to get the Counterfactual video for the uploaded video
  const getCFForUploadedVideo = async (ef_) => {
    setLoading(true);
    SpinnLoad(true, "Counterfactual");
    console.log("Getting CF for uploaded video");
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL_BACKEND}/getCF`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Allow-Control-Allow-Origin': '*',
          'Allow-Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({ ef_ })   // Send the percentage of the selected video to the server
      });
  
      const data = await response.json();
      console.log('Data received:', data);
  
      const { ed: Bed, es: Bes, bev: Lvef } = data;   // Get the data from the response
      console.log(Bed, Bes, Lvef);
  
      setCedPromise(Bed);   // Set the Ced value
      setCesPromise(Bes);   // Set the Ces value
      setVideoDataCFPromise(Lvef);    // Set the percentage of the Counterfactual video
  
    } catch (error) {
      console.error('Error fetching video data:', error);
    } finally {   // Stop the loading state with a small delay to ensure the data are loaded
      setTimeout(() => {  
        SpinnLoad(false, "Counterfactual");
      }, 4000);
    }
  };

  // Function to get the class of the selected video depending on the Ejection Fraction (EF)
  function getClassByEf(ef){
    if (ef < 40)  // If the EF is less than 40, the class is Reduced
      return("Reduced");
    if (ef < 55)  // If the EF is between 40 and 55, the class is Normal
      return("Normal");
    return("Abnormal"); // If the EF is greater than 55, the class is Abnormal
  }

  // Function to fetch the frames of the GIFs
  const fetchGifFrames = async (folderPath, name) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL_FRONT_NODE}/getFramesFromFolder`, {
        folderPath: folderPath
      });
      console.log('Frames fetched successfully');
      // Depending on the name, fetch and set the frames of the GIFs.
      switch(name){
        case "vi1":       // Case of the selected video not segmented
          setFrames_vi1(response.data);  // Set the frames of the selected video
          console.log("Frames vi1 fetched");
          break;
        case "vi2":       // Case of the counterfactual video not segmented
          setFrames_vi2(response.data);
          console.log("Frames vi2 fetched");
          break;
        case "v1s":       // Case of the selected video segmented
          setFrames_v1s(response.data);
          console.log("Frames v1s fetched");
          break;
        case "v2s":       // Case of the counterfactual video segmented
          setFrames_v2s(response.data);
          console.log("Frames v2s fetched");
          break;
        default:
          break;
      }
      return;
    } catch (error) {
      console.error('Error fetching frames', error);
    }
  };

  /* Queue state */
  if (inQueue) {
    return (
      <div className="loading-page">
        <div className="loading">
          <svg width="16px" height="12px">  {/* Loading animation */}
            <polyline id="back" points="1 6 4 6 6 11 10 1 12 6 15 6"></polyline>
            <polyline id="front" points="1 6 4 6 6 11 10 1 12 6 15 6"></polyline>
          </svg>
        </div>
        <p className="loading-text">You are currently in the queue. Your position : {queuePosition}</p> {/* Display the queue position */}
      </div>
    );
  }

  /* Loading state */
  if (loading) {
    return <div className="loading-page">
      <div className="loading">
        <svg width="16px" height="12px">  {/* Loading animation */}
          <polyline id="back" points="1 6 4 6 6 11 10 1 12 6 15 6"></polyline>
          <polyline id="front" points="1 6 4 6 6 11 10 1 12 6 15 6"></polyline>
        </svg>
        </div>
        {/* Message for the current data loading */}
        <p className="loading-text">Currently loading : {loading_type}</p>  {/* loading_type displays the current loading type */}
      </div>;
  }
  else {
    return (
      <div className="predict-page">
        {loading}   {/* Loading state */}
        <div className="left-container">
          {/* Upload video form */}
          <div className="form-container">
            <form className="form" onSubmit={handleSubmit}>   {/* Form to upload the video. handleSubmit is called when the form is submitted with the Upload*/}
              <label htmlFor="file-input" className="file-input-label">
                Browse
              </label>
              <input id="file-input" className="file-input" type="file" accept="video/*" onChange={handleFileChange} />
              {selectedFile && <input type="text" value={selectedFile.name} readOnly className="file-name-input"/>}
              <button className="upload-button" type="submit">Upload</button>
            </form>
          </div>
          
          {/* Class change and video preview */}
          <hr id="hr-left-container"></hr>
          {/* Insertion of the 'Dropdown' component to change the current video class */}
          <Dropdown onDataUpdate={handleDataUpdate} SpinnerLoading={SpinnLoad}/>
          {hasInitialDataLoaded && <div className="gif-container">
            <div className="selected-gif image">
              <h2>Selected class</h2>
              <img src={pathVideo1} alt="Selected class video" className={zoomedImg === 'img1' ? 'zoomed_margin' : ''} onClick={() => toggleZoom('img1')}/>
              {/* Add the Brisque score of the current uploaded video */}
              {/*<p>{brisqueBase ? `Brisque score: ${brisqueBase}` : ''}</p>*/}
            </div>
            <div className="counterfactual-gif image">
              <h2>Counterfactual</h2>
              <img src={pathVideo2} alt="Counterfactual class video" className={zoomedImg === 'img2' ? 'zoomed' : ''} onClick={() => toggleZoom('img2')}/>
            </div>
          </div>}
        </div>
        
        {/* Right container with the analyzed videos (blank if not loaded) */}
        {hasInitialDataLoaded && <div className="right-container">
          <div className="base">
            <h2>
              {/* Selected video data */}
              Selected video : 
              {videoDataBase ? ` ${videoDataBase}% - ${getClassByEf(videoDataBase)}` : ''}
            </h2>
            <div className="selected-container">
              <div className="left-button-container">
                {/* Button to open the Frame by Frame modal for the selected video */}
                <button className="fbf-btn" onClick={() => handleOpenModalGIF('selected')}>Frame by frame</button>
              </div>
              <div className="selected-card image">
                {/* Base end Diastolic image for the selected video */}
                <img className={zoomedImg === 'img3' ? 'zoomed right-container-img' : 'right-container-img'} src={`data:image/png;base64,${returnCurrentSideFrame('vi1', 'bed')}`} alt="Base end Diastolic" onClick={() => toggleZoom('img3')}/>
                <p>Base end Diastolic</p>
              </div>
              <div className="selected-card image" id="selected_gif">
                {/* Selected video GIF */}
                <img src={pathVideo1} alt="Base LVEF" className={zoomedImg === 'img4' ? 'zoomed right-container-img' : 'right-container-img'} onClick={() => toggleZoom('img4')}/>
                <p>Base LVEF</p>
              </div>
              <div className="selected-card image">
                {/* Base end Systolic image for the selected video */}
                <img className={zoomedImg === 'img5' ? 'zoomed right-container-img' : 'right-container-img'} src={`data:image/png;base64,${returnCurrentSideFrame('vi1', 'bes')}`} alt="Base end Systolic" onClick={() => toggleZoom('img5')}/>
                <p>Base end Systolic</p>
              </div>
              <div className="right-button-container">
                <div className="top-buttons">
                  
                  <button className="segment-btn" onClick={() => handleOpenModal('selected')}>Segment</button>
                  <button className="unsegment-btn" onClick={() => handleUnsegment('selected')}>Unsegment</button>
                </div>
              </div>
            </div>
          </div>
          <div className="counterfactual">
            <h2>
              {/* Counterfactual video data */}
              Counterfactual video : 
              {videoDataCF ? ` ${videoDataCF}% - ${getClassByEf(videoDataCF)}` : ''}
            </h2>
            <div className="cf-container">
              <div className="left-button-container">
                {/* Button to open the Frame by Frame modal for the counterfactual video */}
                <button className="fbf-btn" onClick={() => handleOpenModalGIF('counterfactual')}>Frame by frame</button>
              </div>
              <div className="cf-card image">
                {/* Counterfactual end Diastolic image for the counterfactual video */}
                <img src={`data:image/png;base64,${returnCurrentSideFrame('vi2', 'ced')}`} alt="Counterfactual end Diastolic" className={zoomedImg === 'img6' ? 'zoomed right-container-img' : 'right-container-img'} onClick={() => toggleZoom('img6')}/>
                <p>Counterfactual end Diastolic</p>
              </div>
              <div className="cf-card image">
                {/* Counterfactual video GIF */}
                <img src={pathVideo2} alt="Counterfactual LVEF" id="counterfactual_gif" className={zoomedImg === 'img7' ? 'zoomed right-container-img' : 'right-container-img'} onClick={() => toggleZoom('img7')}/>
                <p>Counterfactual LVEF</p>
              </div>
              <div className="cf-card image">
                {/* Counterfactual end Systolic image for the counterfactual video */}
                <img src={`data:image/png;base64,${returnCurrentSideFrame('vi2', 'ces')}`} alt="Counterfactual end Systolic" className={zoomedImg === 'img8' ? 'zoomed right-container-img' : 'right-container-img'} onClick={() => toggleZoom('img8')}/>
                <p>Counterfactual end Systolic</p>
              </div>
              <div className="right-button-container">
                <div className="top-buttons">
                  {/* Buttons to segment and unsegment the counterfactual video */}
                  <button className="segment-btn" onClick={() => handleOpenModal('counterfactual')}>Segment</button>
                  <button className="unsegment-btn" onClick={() => handleUnsegment('counterfactual')}>Unsegment</button>
                </div>
              </div>

              {isModalOpen && (
                <div className="modal">
                  <div className="modal-content">
                    {/* Red cross button to close the modal */}
                    <i className="fa-solid fa-circle-xmark xmark" onClick={handleCloseModal}></i>
                    <div className="dropdowns">
                      <h3>{modalSource === 'selected' ? 'Selected Video Segmentation' : 'Counterfactual Video Segmentation'}</h3>
                      <hr id="hr-modal"></hr>
                      {/* Dropdowns to select the segmentation method, number of superpixels and compactness */}
                      <div className="dd-seg">
                        {/* Select the segmentation method */}
                        <label className="dd-seg-label">Segmentation method </label>
                        <select className="dd-seg-select" value={segmentationMethod} onChange={(e) => setSegmentationMethod(e.target.value)}>
                          <option value="Slic">Slic</option>
                          <option value="Slico">Slico</option>
                        </select>
                      </div>
                      <div className="dd-suppx">
                        {/* Input to select the number of superpixels */}
                        <label className="dd-suppx-label">Number of superpixels </label>
                        <input type="number" className="dd-suppx-input" min="1" value={numberSuperpixels} onChange={(e) => setNumberSuperpixels(Number(e.target.value))} />
                      </div>
                      <div className="dd-comp">
                        {/* Input to select the compactness */}
                        <label className="dd-comp-label">Compactness </label>
                        <input type="number" className="dd-comp-input" min="1" value={compactness} onChange={(e) => setCompactness(Number(e.target.value))} />
                      </div>
                      {/* Button to segment the video */}
                      <button className="modal-segment-btn" onClick={handleSegment}>Segment</button>
                    </div>
                  </div>
                </div>
              )}

              {isModalGIFOpen && (
                /* Modal for the Frame by Frame GIF */
                <div className="modal">
                  <div className="modal-content">
                    {/* Red cross button to close the modal */}
                    <i className="fa-solid fa-circle-xmark xmark" onClick={handleCloseModalGIF}></i>
                    <div className="fbf-modal">
                      <h3>{modalSource === 'selected' ? 'Selected Video Frame by Frame' : 'Counterfactual Video Frame by Frame'}</h3>
                      <hr id="hr-fbf-modal"></hr>
                      {returnCurrentModalFrameName().length > 0 ? (
                        <>
                          <div className="fbf-container"> 
                            <div className="fbf-btn-container-left">
                              {/* Button to jump to end systolic frame */}
                              <button className="fbf-buttons" onClick={esFrame}>Freeze ES</button>
                              {/* Button to go to the previous frame */}
                              <button className="fbf-buttons" onClick={previousFrame}>Previous Frame</button>
                              {/* Button to jump to the first frame */}
                              <button className="fbf-buttons" onClick={() => setSliderValue(0)}>First Frame</button>
                            </div>
                            <div className="fbf-slider-container">
                              {/* Display the current frame number and the total number of frames */}
                              <p>Frame {parseInt(sliderValue)+1} of {returnCurrentModalFrameName().length}</p>
                              <img className="fbf-img" src={`data:image/png;base64,${returnCurrentModalFrameName()[sliderValue]}`} alt={`Frame ${sliderValue}`} />
                              {/* Slider to navigate through the frames */}
                              <input 
                                type="range" 
                                min="0" 
                                max={returnCurrentModalFrameName().length - 1} 
                                value={sliderValue} 
                                onChange={handleSliderChange} 
                                className="slider" 
                              />
                            </div>
                            <div className="fbf-btn-container-right">
                              {/* Button to jump to the end dyastolic frame */}
                              <button className="fbf-buttons" onClick={edFrame}>Freeze ED</button>
                              {/* Button to go to the next frame */}
                              <button className="fbf-buttons" onClick={nextFrame}>Next Frame</button>
                              {/* Button to jump to the last frame */}
                              <button className="fbf-buttons" onClick={() => setSliderValue(returnCurrentModalFrameName().length - 1)}>Last Frame</button>
                            </div>
                          </div>
                        </>
                      ) : (
                        /* Loading message ffor the frames */
                        <p>Loading frames...</p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
  }
  {/* Check if eather a class or an uploaded video has been selected */}
  {!hasInitialDataLoaded &&
  <div className="right-container">
    <div className="base">
      <h2>Please upload a video of your own or choose a class to get started</h2>
    </div>
  </div>
  }
    </div>
    );
  }
};

export default PredictPage;