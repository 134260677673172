import React from 'react';
import { Link } from 'react-router-dom';
import './css/HomePage.css';

/* The home page */

const HomePage = () => {
  return (
    <div className="home-page">
        {/* Title of the main page */}
        <h1 className="main-title">Echo Explore</h1>
        <hr className="hr-title-home"></hr>
        <div className="cards-container">
            {/* Cards to navigate to the different pages */}

            {/* Card to navigate to the "Predict" page */}
            <Link to="/predict" className="card">
                <img src={require("../images/page_previews/predict_page.png")} alt="Predict" />
                <h3 className="card-title">Predict</h3>
                <hr className="hr-card"></hr>
                <p>Upload your video to know the AI prediction on your echocardiogram.</p>
            </Link>

            {/* Card to navigate to the "Video" page */}
            <Link to="/videos" className="card">
                <img src={require("../images/page_previews/video_page.png")} alt="Videos" />
                <h3 className="card-title">Videos</h3>
                <hr className="hr-card"></hr>
                <p>A page to understand and compare the results obtained with other videos to help you interpret them.</p>
            </Link>

            {/* Card to navigate to the "Explanation" page */}
            <Link to="/explanation" className="card">
                <img src={require("../images/page_previews/explanation_page.png")} alt="Explanation" />
                <h3 className="card-title">Explanation</h3>
                <hr className="hr-card"></hr>
                <p>A simple page explaining the different technical terms used in the various services offered.</p>
            </Link>

            {/* Card to navigate to the "Sources & Credits" page */}
            <Link to="/sources-credits" className="card">
                <img src={require("../images/page_previews/source_page.png")} alt="Sources & Credits" />
                <h3 className="card-title">Sources & Credits</h3>
                <hr className="hr-card"></hr>
                <p>The list of sources used to carry out this project, as well as the people involved and the copyrights.</p>
            </Link>
        </div>
    </div>
  );
};

export default HomePage;