// ConditionalNavbar.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar'; // Adjust the import path as necessary


// ConditionalNavbar component to render Navbar only on pages other than HomePage
const ConditionalNavbar = () => {
  const location = useLocation();

  // Do not render Navbar on HomePage
  if (location.pathname === '/home') {
    return;
  }

  return <Navbar />;
};

export default ConditionalNavbar;